import { createAction, props } from "@ngrx/store";

export const loadNutrientFilters = createAction(
  "[Nutrient Filter / API] Load Nutrient Filters",
);
export const loadNutrientFiltersSuccess = createAction(
  "[Nutrient Filter / API] Load Nutrient Filters Success",
  props<{ nutrientFilters: any[] }>(),
);
export const loadNutrientFiltersFailure = createAction(
  "[Nutrient Filter / API] Load Nutrient Filters Failure",
  props<{ error: any }>(),
);

export const updateNutrientFilter = createAction(
  "[Nutrient Filter] Update Nutrient Filter",
  props<{ nutrientCode: string }>(),
);

export const applyNutrientFilters = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters",
);
export const applyNutrientFiltersProducts = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters Products",
);
export const applyNutrientFiltersRation = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters Ration",
);
export const applyNutrientFiltersRecipeCreate = createAction(
  "[Category Filter / API] Apply Nutrient Filters Recipe Create",
);

export const resetAppliedNutrientFilters = createAction(
  "[Nutrient Filter] Reset Applied Nutrient Filters",
);

export const resetNutrientFilters = createAction(
  "[Nutrient Filter] Reset Nutrient Filters",
);

export const restoreAppliedNutrientFilters = createAction(
  "[Nutrient Filter] Restore Applied Nutrient Filters",
);

export const clearNutrientFilters = createAction(
  "[Nutrient Filter] Clear Nutrient Filters",
);
