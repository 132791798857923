import { Injectable } from "@angular/core";
import { ProductsService } from "@app/core/services/products.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromNutrientFilter from "../../store";
import * as NutrientFilterActions from "../actions/nutrient-filter.actions";
import * as NutrientFilterSelectors from "../selectors/nutrient-filter.selectors";

@Injectable()
export class NutrientFilterEffects {
  loadNutrientFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NutrientFilterActions.loadNutrientFilters),
      concatLatestFrom((action) => [
        this.store.pipe(
          select(NutrientFilterSelectors.selectAllNutrientFilters()),
        ),
      ]),
      switchMap(([action, allNutrientFilters]) => {
        if (allNutrientFilters.length) {
          return of(
            NutrientFilterActions.loadNutrientFiltersSuccess({
              nutrientFilters: allNutrientFilters,
            }),
          );
        }

        return this.productsService.loadProductNutrientFilters().pipe(
          map((response) =>
            NutrientFilterActions.loadNutrientFiltersSuccess({
              nutrientFilters: response,
            }),
          ),
          catchError((error) =>
            of(NutrientFilterActions.loadNutrientFiltersFailure({ error })),
          ),
        );
      }),
    ),
  );

  applyNutrientFilters$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(NutrientFilterActions.applyNutrientFilters),
      concatLatestFrom((action) => [
        this.store.pipe(select(NutrientFilterSelectors.selectActivePage)),
      ]),
      map(([action, activePage]): any => {
        const pageNameList = [
          "products",
          "recipe-create",
          "ration-report-day-page",
        ];

        if (activePage === pageNameList[0]) {
          return NutrientFilterActions.applyNutrientFiltersProducts();
        }

        if (activePage === pageNameList[1]) {
          return NutrientFilterActions.applyNutrientFiltersRecipeCreate();
        }

        if (activePage === pageNameList[2]) {
          return NutrientFilterActions.applyNutrientFiltersRation();
        }
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromNutrientFilter.State>,
    private productsService: ProductsService,
  ) {}
}
